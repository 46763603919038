import {Injectable} from '@angular/core';
import {createClient, SupabaseClient, User} from '@supabase/supabase-js';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SupabaseService {

  supabase: SupabaseClient;

  private _currentUser: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private router: Router) {
    this.supabase = createClient('https://api.formaturf.polytan.de', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5lY3l1a2dqZm9qaWNqdmtwb2xzIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY0Nzk4NjI4OSwiZXhwIjoxOTYzNTYyMjg5fQ.dWrlNKV32zfL7LJLISe8mPF7t-j3FAin8zMInK9mon4', {
      autoRefreshToken: true,
      persistSession: true
    });

    this.loadUser();

    this.supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        // eslint-disable-next-line no-underscore-dangle
        this._currentUser.next(session.user);
      } else {
        // eslint-disable-next-line no-underscore-dangle
        this._currentUser.next(false);
      }
    });
  }

  async loadUser() {
    const user = await this.supabase.auth.user();

    if (user) {
      // eslint-disable-next-line no-underscore-dangle
      this._currentUser.next(user);
    } else {
      // eslint-disable-next-line no-underscore-dangle
      this._currentUser.next(false);
    }
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get currentUser(): Observable<User> {
    // eslint-disable-next-line no-underscore-dangle
    return this._currentUser.asObservable();
  }

  async logout() {
    this.supabase.auth.signOut().then(_ => {
      // Clear up and end all active subscriptions!
      this.supabase.getSubscriptions().map(sub => {
        this.supabase.removeSubscription(sub);
      });

      this.router.navigateByUrl('/login');
    });
  }

  signIn(email, password) {
    return new Promise(async (resolve, reject) => {
      // @ts-ignore
      const {error, data} = await this.supabase.auth.signIn({email, password});
      if (error) {
        reject(error);
      } else {
        if (data.user.user_metadata.isAdmin) {
          resolve(data);
        } else {
          reject({
            message: 'No access',
            status: 400
          });
        }
      }
    });
  }

  async getUserById(id) {
    const data = await this.supabase.auth.api.getUserById(id);

    return data.data;
  }

  async getUsers() {
    const data = await this.supabase.auth.api.listUsers();
    return data.data;
  }

  async createUser(email, password, admin = false) {
    const checkUser = await this.checkIfUserExists(email);

    if (!checkUser) {
      const user = await this.supabase.auth.api.createUser({
        email,
        password,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        email_confirm: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        user_metadata: {isAdmin: admin}
      });

      return user;
    } else {
      return false;
    }
  }

  async editUser(id, email, password, admin = false) {
    const user = await this.supabase.auth.api.updateUserById(id, {
      email,
      password,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      user_metadata: {isAdmin: admin}
    });

    return user.data;
  }

  async checkIfUserExists(email) {
    const users = await this.getUsers();
    const user = users.filter((el) => {
      return el.email === email;
    });

    if (user.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  async deleteUser(id) {
    const data = await this.supabase.auth.api.deleteUser(id);

    return data.data;
  }

  async getScans() {
    const data = await this.supabase.from('scans').select();

    console.log(data);
  }

}
